<script>
import _ from 'lodash'
export default {
	name: 'CommonTable',
	props: {
		totalPage: {
			type: Number,
			required: true
		},
		onPageChange: {
			type: Function,
			required: true
		},
		selectedRows: {
			type: Array,
			default: () => null
		},
		rowsPerPage: {
			type: Number,
			default: 100
		},
		currentPage: {
			type: Number,
			default: 1
		},
		currentSort: {
			type: Object,
			default: () => ({
				descending: false
			})
		}
	},
	data() {
		return {
			pagination: {
				descending: false,
				page: 1,
				rowsPerPage: this.rowsPerPage
			},
			rowsPerPageOptions: [],
			selected: [],
			selection: 'none',
			loading: true
		}
	},
	computed: {
		offsetData() {
			const length =
				(this.pagination.page - 1) * this.pagination.rowsPerPage
			const data = this.$attrs.data || []
			return [...Array.from({ length }, () => null), ...data]
		}
	},
	watch: {
		currentPage(currPage) {
			if (this.pagination.page !== currPage) {
				this.pagination.page = currPage ?? 1
			}
		},
		currentSort(sort) {
			if (this.pagination.descending !== sort.descending) {
				this.pagination.descending = sort.descending
			}
			if (this.pagination.sortBy !== sort.sortBy) {
				this.pagination.sortBy = sort.sortBy
			}
		},
		pagination(value) {
			this.withLoading(this.onPageChange)({
				...value,
				limit: value.rowsPerPage
			})
		},
		['pagination.page'](value) {
			this.onPageIndexChange(value)
		},
		selected(ids) {
			this.$emit('selection', ids)
		},
		selectedRows(rows) {
			this.selected = rows
		},
		rowsPerPage(perPage) {
			this.pagination.rowsPerPage = perPage
		}
	},
	created() {
		if (this.selectedRows) {
			this.selection = 'multiple'
		}
	},
	methods: {
		onPageIndexChange(page) {
			this.withLoading(this.onPageChange)({
				page,
				limit: this.pagination.rowsPerPage,
				descending: _.get(this.pagination, 'descending', null),
				sortBy: _.get(this.pagination, 'sortBy', null)
			})
		},
		withLoading(func) {
			return async (...args) => {
				this.loading = true
				await func(...args)
				this.loading = false
			}
		}
	}
}
</script>

<template>
	<div id="common-table">
		<div id="common-q-table">
			<q-table
				:page="pagination.page"
				:pagination.sync="pagination"
				:selected.sync="selected"
				v-bind="{ ...$props, ...$attrs }"
				:data="offsetData"
				:rows-per-page-options="rowsPerPageOptions"
				:selection="selection"
				:sort-method="row => row"
				hide-pagination
				flat
				bordered
				dense
			>
				<template
					v-for="(_, name) in $scopedSlots"
					#[`body-cell-${name}`]="data"
				>
					<q-td :key="name">
						<slot :name="name" v-bind="data" />
					</q-td>
				</template>
				<template #top-left>
					<slot name="top-left" />
				</template>
				<template #top-right>
					<q-pagination
						v-model="pagination.page"
						:max="totalPage"
						:max-pages="8"
						direction-links
					/>
				</template>
				<template #bottom>
					<q-pagination
						v-model="pagination.page"
						:max="totalPage"
						:max-pages="8"
						direction-links
					/>
				</template>
			</q-table>
			<div v-if="loading" id="common-table-loading">
				<q-spinner color="primary" size="5em" />
			</div>
		</div>
	</div>
</template>

<style lang="scss">
#common-q-table {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	.q-table__container {
		.q-table {
			tbody {
				tr:nth-child(even) {
					background: #eee;
				}
			}
		}
	}

	> div {
		flex: 1;
		align-self: stretch;
	}

	.q-pagination.row {
		margin: 10px;
		justify-self: flex-end;
	}

	.row {
		margin: 0;
	}

	.q-table__container {
		.q-table__bottom {
			justify-content: center;
		}
	}

	#common-table-loading {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.8);
		text-align: center;
		padding: 250px;
	}
}
</style>
